import Collapse from "@mui/material/Collapse"
import { Typography } from "components/typography"
import { Link } from "gatsby"
import ReactMarkdown from "markdown-to-jsx"
import React, { useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "./style.scss"

// https://daringfireball.net/projects/markdown/

let questionClickActionRef
let openQuestionRef = undefined

const questionClick = props => {
  questionClickActionRef(props.id)
}

const aClick = props => {}

const defaultOptions = {
  overrides: {
    h1: {
      component: ({ ...props }) => <Typography.H1 {...props} />,
    },
    h2: {
      component: ({ ...props }) => {
        return (
          <div style={{ marginTop: "60px" }}>
            <Typography.H2 {...props} />
          </div>
        )
      },
    },
    h3: {
      component: ({ ...props }) => {
        return (
          <div style={{ marginTop: "40px" }}>
            <Typography.H3 comp="article" {...props} />
          </div>
        )
      },
    },
    h6: {
      component: ({ ...props }) => <Typography.H6 {...props} />,
    },
    p: {
      component: ({ ...props }) => <Typography.P comp="bottom-margin" {...props} />,
    },
    quote: {
      component: ({ ...props }) => {
        return (
          <div style={{ display: "flex", marginTop: "20px" }}>
            <LazyLoadImage src="/images/icon_quote.png" style={{ width: "20px", height: "17.5px", marginRight: "15px" }} />
            <Typography.P {...props} />
          </div>
        )
      },
    },
    caption: {
      component: ({ ...props }) => {
        return (
          <div>
            <Typography.P {...props} />
          </div>
        )
      },
    },
    source: {
      component: ({ ...props }) => {
        return (
          <div style={{ marginBottom: "20px" }}>
            <Typography.P comp="small-bold" fontColor="#E7527A" {...props} />
          </div>
        )
      },
    },
    q: {
      component: ({ ...props }) => {
        return (
          <div style={{ marginTop: "60px" }}>
            <Typography.H2 {...props} />
          </div>
        )
      },
    },
    strong: {
      component: props => (
        <span className="markdown__strong">
          <span {...props} />
        </span>
      ),
    },
    answer: {
      component: props => {
        return (
          <Collapse in={openQuestionRef === props.id} timeout="auto" unmountOnExit>
            <div className="markdown-question__container">
              <div className="markdown-question-index"></div>
              <Typography.P comp="faq-closed" {...props} />
            </div>
          </Collapse>
        )
      },
    },
    question: {
      component: props => {
        const style = openQuestionRef === props.id ? "faq-opened" : "faq-closed"
        return (
          <div role="button" tabIndex={0} className="markdown-question__container" style={{ cursor: "pointer" }} onKeyDown={() => questionClick(props)} onClick={() => questionClick(props)}>
            <Typography.P comp={style} className="markdown-question-index">
              {props.id}.
            </Typography.P>
            <Typography.P comp={style} {...props} />
          </div>
        )
      },
    },
    ul: {
      component: props => {
        return (
          <ol className="markdown__ul">
            <span {...props} />
          </ol>
        )
      },
    },
    a: {
      component: props => {
        return props.href !== "/credit-cards/instructions" ? (
          <a
            href={props.href}
            target="_blank"
            // target={props.href.includes("mailto") ? "_blank" : ""}
            // to={{ pathname: props.href }}
            {...props}
            style={{ color: "#4f8ccc", textDecoration: "inherit" }}
            onClick={() => aClick(props)}
            onKeyDown={() => aClick(props)}
          >
            <span {...props} />
          </a>
        ) : (
          <Link to={props.href} style={{ color: "#4f8ccc", textDecoration: "inherit" }}>
            <span {...props} />
          </Link>
        )
      },
    },
    ol: {
      component: props => {
        return (
          <ol className="markdown__ol">
            <span {...props} />
          </ol>
        )
      },
    },
    li: {
      component: props => {
        return (
          <li className="markdown__li">
            <span {...props} />
          </li>
        )
      },
    },
    img: {
      component: props => {
        return (
          <span style={{ position: "relative", marginRight: "40px" }}>
            <LazyLoadImage
              {...props}
              style={{
                position: "absolute",
                bottom: "2px",
                width: "16px",
                height: "16px",
                maxWidth: "16px",
                maxHeight: "16px",
              }}
              alt=""
            />
          </span>
        )
      },
    },
    stayorgocomau: {
      component: props => {
        return (
          <span style={{ color: "#202020" }}>
            <span className="markdown__strong">stay</span>
            or
            <span className="markdown__strong">go</span>
            .com.au
          </span>
        )
      },
    },
  },
}

function Markdown({ md, isArticle = false, overrides = {}, ignoreDefaultOverrides = false, ...props }) {
  const [open, setOpen] = useState(undefined)
  openQuestionRef = open

  const questionClickAction = index => {
    // let temp = open.concat([]);
    // temp[index] = !temp[index];
    // setOpen(temp);

    setOpen(open === index ? undefined : index)
  }

  questionClickActionRef = questionClickAction

  const options = { ...defaultOptions }
  Object.keys(options).forEach(o => {
    options[o] = { ...options[o] }
  })

  if (ignoreDefaultOverrides) {
    options.overrides = {}
  }

  if (isArticle) {
    options.overrides = {
      ...options.overrides,
      ...{
        img: {
          component: props => {
            return (
              <span style={{ display: "flex", justifyContent: "center" }}>
                <LazyLoadImage {...props} style={{ width: "100%", marginTop: "30px", maxWidth: "648px" }} alt="" />
              </span>
            )
          },
        },
        question: {
          component: props => {
            return (
              <div style={{ marginTop: "60px" }}>
                <Typography.H2 {...props} />
              </div>
            )
          },
        },
        answer: {
          component: props => {
            return <Typography.P {...props} />
          },
        },
      },
    }
  }

  if (overrides) {
    options.overrides = {
      ...options.overrides,
      ...overrides,
    }
  }

  if (isArticle) {
    return <ReactMarkdown options={options} children={md} />
  } else {
    return (
      <div className="page-container">
        <div className="markdown__container">
          <ReactMarkdown options={options} children={md} />
        </div>
      </div>
    )
  }
}

export default Markdown
