import { Typography } from "components/typography"
import Layout from "components/layout"
import Markdown from "components/markdown"
import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const md = `
# Stay or Go Pty Ltd Financial Services Guide

Stay or Go Pty Ltd (‘Stay or Go’’) (Authorised Representative No.: 001301898) operates as an Authorised Representative of Mozo Pty Ltd who is the holder of Australian Financial Services Licence (AFSL No: 328141)

Date prepared:  27 February 2023

### What is the purpose of this Financial Services Guide?

This Financial Services and Credit Guide (“__FSG__”) is prepared by Stay or Go to give you sufficient information to assist you in deciding on whether you wish to use the financial services which Stay or Go provides. This FSG should be read prior to utilising Stay or Go’s financial services.

To assist you in making a decision on whether you wish to use Stay or Go’s services, in this FSG we outline:
- Who we are
- How you can contact Stay or Go and Mozo
- The financial services Stay or Go and Mozo are authorised to provide under Mozo’s financial services licence
- Are there any relationships that exist that may influence us when we provide advice?
- The remuneration and commission we will receive if you use our services
- Will anyone be paid for referring me to you?
- Are we adequately insured?
- Details of our Dispute Resolution Procedures if you wish to make a complaint

### Who we are?

With Stay or Go, you no longer need to switch banks for a better deal. We work to get you a better deal with your current bank and compare it to the most relevant offers from across the market.

Our purpose at Stay or Go is simple: to empower loyal customers.

With deep experience in both banking and technology, the Stay or Go team have led the introduction of many market innovations, including contactless payments, 0% balance transfers, and market leading low rate and reward cards. We understand the market intimately and are focused on bringing you the simplest way to get a better deal with the right bank.

### How you can contact Stay or Go and Mozo

**Stay or Go:**  
Business Address: Stay or Go, Stone & Chalk, 121 King St, Melbourne VIC 3000  
Website: www.stayorgo.com.au  
Email: info@stayorgo.com.au  

Mozo who can be contacted as follows:  

**Mozo Pty Ltd**  
Level 10  
89 York St  
Sydney NSW 2000  
Email: hello@mozo.com.au  
Website: [www.mozo.com.au](www.mozo.com.au)

### What Financial Services is Stay or Go authorised to provide under Mozo’s AFSL?
Under the Corporations Act 2001, an Australian Financial Services Licence is required to be authorised to provide advice on certain financial products. Mozo has obtained an AFSL so that it can offer advice on these financial products. 

As an authorised representative of Mozo's AFSL, Stay or Go provides general advice only. It does not constitute “personal advice”, as it does not take into account one or more of your financial objectives, circumstances or needs. We are obliged to warn you that our general advice provided to you does not take account of your objectives, financial situation or needs. Therefore, we recommend that you ensure that you obtain advice from a licensed professional regarding the suitability for your own circumstances, of utilising our products and services.

Specifically, the authorisations under Mozo’s AFSL which it has authorised Stay or Go to operate under are as follows:

<em>
<p>(a) provide general financial product advice for the following classes of financial products:</p>
<p style="padding-left:30px;margin-bottom:0px">(i) deposit and payment products including:</p>
<p style="padding-left:60px">(A) basic deposit products;</p>
<p style="padding-left:60px">(B) deposit products other than basic deposit products;</p>
<p style="padding-left:60px">(C) non-cash payment products; and</p>
<p style="padding-left:30px">(ii) general insurance products; and</p>
<p style="padding-left:30px">(iii) life products limited to:</p>
<p style="padding-left:60px">(A) life risk insurance products as well as any products issued by a Registered Life Insurance Company that are backed by one or more of its statutory funds limited; and</p>
<p style="padding-left:30px">(iv) securities:</p>
<p style="padding-left:30px">(v) standard margin lending facility; and</p>
<p>(b) deal in a financial product by:</p>
<p style="padding-left:30px">(i) applying for, acquiring, varying or disposing of a financial product on behalf of another person in respect of the following classes of products:</p>
<p style="padding-left:60px">(A) deposit and payment products including:</p>
<p style="padding-left:90px">(1) basic deposit products;</p>
<p style="padding-left:90px">(2) deposit products other than basic deposit products;</p>
<p style="padding-left:90px">(3) non-cash payment products; and</p>
<p style="padding-left:60px">(B) general insurance products;</p>
<p style="padding-left:60px">(C) life products limited to:</p>
<p style="padding-left:90px">(1) life risk insurance products as well as any products issued by a Registered Life Insurance Company that are backed by one or more of its statutory funds;</p>
<p style="padding-left:60px">(D) securities; and</p>
<p style="padding-left:60px">(E) standard margin lending facility;</p>
to retail and wholesale clients.
</em>

### Are there any relationships that exist that may influence us when we provide advice?

Neither Mozo or Stay or Go (including employees) has any associations or relationships with product issuers, that might reasonably be expected to be capable of influencing the services Stay or Go provides other than those described in this financial services guide.

### Mozo’s remuneration arrangements

Mozo may receive fees or commissions from the financial institutions, issuers of financial products and third party advice providers that it displays on its site and other 3rd parties for the following activities:

<p>•&nbsp;Fees for market research or data and helping 3rd parties use the data Mozo has collected</p>
<p>•&nbsp;Licensing and hosting fees for content such as financial calculators and other tools</p>
<p>•&nbsp;Advertising fees for product advertisements based on a Cost per impression, Cost per click or Fixed fee</p>
<p>•&nbsp;Licensing fees for the use of badges awarded in Mozo’s Expert’s Choice and other awards programs</p>
<p>•&nbsp;Fees for referrals to product issuers on the following basis</p>
<p style="padding-left:30px">○&nbsp;Cost per click when a person clicks from Mozo’s website through to a product issuer's or third party advice provider's website</p>
<p style="padding-left:30px">○&nbsp;Cost per application when a person completes an application for a product issuer's product after being referred from Mozo</p>
<p style="padding-left:30px">○&nbsp;Cost per approved account when a person completes and is approved for a product issuer's product after being referred from Mozo</p>
<p style="padding-left:30px">○&nbsp;Cost per lead when a person clicks through to a product issuer's or third party advice provider's website and submits his or her contact details, or requests that Mozo passes on his or her contact details to a product issuer or third party advice provider</p>
<p style="padding-left:30px">○&nbsp;Commission share on enquiries referred to product issuers or third party advice providers</p>

The rates of the fees and commissions that Mozo receives vary between financial institutions, product issuers and third parties. It is important to note that:
- The fee or commission received by Mozo does not change the product its clients receive, or the fees, rates or commissions the client is charged by the financial institutions or providers of financial products
- Mozo provides its services free to consumers

The amount of fees and commissions Mozo receives will vary greatly due to the large number of providers and products Mozo represents on the site, so will depend on the specific products which customers choose.

Mozo staff and contractors are remunerated directly by Mozo and not in any way by other financial institutions or providers of financial products.

Other than as set out in this financial services guide, Mozo does not have any association or relationship with the financial institutions and providers of financial products that they display on their website.

You can request further information on Mozo’s commissions and remuneration by contacting them on compliance@mozo.com.au.

### Stay or Go’s remuneration arrangements

Stay or Go receives a share of the revenue that Mozo is paid by third party product issuers on a cost per click basis or approved application basis, when a person clicks through Stay or Go’s website to that third party’s website, or when a person also successfully applies for a product.

Stay or Go staff and contractors are remunerated directly by Stay or Go and not in any way by the financial product providers.

Stay or Go and its associates do not receive any remuneration or benefits in relation to the financial services we provide, other than disclosed in this financial services guide.

Stay or Go does not charge consumers in relation to the services we provide as an Authorised Representative of Mozo. Stay or Go provides its financial services free to consumers.

<br/><div style="background-color:#CFE2F3;padding:24px 12px;border:1px solid black;">
<h2>Lack of Independence</h2>
Stay or Go is not independent, impartial or unbiased because we receive commissions for  products and third party advice displayed on our website. <br/>
</div><br/>

### Will anyone be paid for referring me to you?

Stay or Go may pay fees to third party websites for referrals to the Stay or Go website.

### Are we adequately insured?

Stay or Go is covered by professional indemnity insurance that satisfies the requirements of the Corporations Act for compensation arrangements. Our insurance covers claims in relation to the conduct of representatives in respect of events occurring during their representations and employees who no longer work for Stay or Go in respect of events occurring during their employment.

### What should I do if I have a complaint or dispute?

Stay or Go takes your complaints seriously, and is committed to trying to resolve them as quickly as possible.

To assist you in any complaints or disputes, we have:
- Established internal dispute resolution procedures
- Become a member of the Australian Financial Complaints Authority which allows you an external source of assistance if you are not satisfied with our resolution

If you wish to make a complaint about our services, in the first instance please email us at info@stayorgo.com.au.

The Stay or Go team will respond to your complaint and seek to come to a satisfactory resolution directly with you.

If the outcome of this is not satisfactory to you, you may refer your complaint to the Australian Financial Complaints Authority (‘AFCA’) of which Stay or Go is a member (Member Number 75123).

To find out more about AFCA and how they can assist you, please contact them as
follows:  
Mail: Australian Financial Complaints Authority Limited, GPO Box 3, Melbourne, VIC 3001  
Website: https://www.afca.org.au/  
Email: info@afca.org.au  
Phone: 1800 931 678  
Fax: (03) 9613 6399`

export default function FSG() {
  const overrides = {
    h2: {
      component: ({ ...props }) => {
        return (
          <div style={{ marginTop: "13px" }}>
            <Typography.H2 {...props} />
          </div>
        )
      },
    },
  }
  return (
    <Layout title="Financial Services Guide | Stay or Go" description="This Financial Services and Credit Guide (“FSG”) is prepared by Stay or Go to give you sufficient information to assist you in deciding on whether you wish to use the financial services which Stay or Go provides." image="/images/Logo_SOG_Colour.png">
      <Markdown md={md} isArticle={false} overrides={overrides} />
    </Layout>
  )
}
